import type { JSX, ReactElement, ReactNode, Ref } from "react";
import type { FieldValues, SubmitHandler, UseFormReturn } from "react-hook-form";
import React, { forwardRef, useCallback, useId, useState } from "react";
import { FormProvider, useFormContext } from "react-hook-form";

import { cn } from "@gility/lib";
import { getErrorFromUnknown } from "@gility/lib/errors";
import { useLocale } from "@gility/lib/hooks/useLocale";

import { showToast, Tooltip } from "../../..";
import { AlertTriangle, Eye, EyeOff, X } from "../../icon";
import { HintsOrErrors } from "./HintOrErrors";
import { InputIcon } from "./InputIcon";
import { Label } from "./Label";

/* eslint-disable @typescript-eslint/no-redundant-type-constituents */

type InputProps = JSX.IntrinsicElements["input"] & { isFullWidth?: boolean };

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { isFullWidth = true, ...props },
  ref,
) {
  return (
    <input
      {...props}
      ref={ref}
      className={cn(
        "block h-10 rounded-[4px] px-3 py-2 text-sm placeholder:text-muted-foreground",
        // "focus:border-pink focus:outline-none focus:ring-[3px] focus:ring-pink/30",
        "border border-input hover:border-gray-400",
        "focus:border-pink focus:outline-none focus:ring-[3px] focus:ring-pink/30",
        isFullWidth && "w-full",
        props.className,
      )}
    />
  );
});

export function InputLeading(props: JSX.IntrinsicElements["div"]) {
  return (
    <span className="inline-flex flex-shrink-0 items-center rounded-l-sm border border-r-0 border-input bg-gray-50 px-3 text-gray-500 sm:text-sm">
      {props.children}
    </span>
  );
}

type InputFieldProps = {
  label?: ReactNode;
  hint?: ReactNode;
  hintErrors?: string[];
  addOnLeading?: ReactNode;
  addOnSuffix?: ReactNode;
  inputIsFullWidth?: boolean;
  addOnFilled?: boolean;
  addOnClassname?: string;
  error?: string;
  labelSrOnly?: boolean;
  containerClassName?: string;
  variant?: "normal" | "success" | "warning" | "error";
  t?: (key: string) => string;
} & React.ComponentProps<typeof Input> & {
    labelProps?: React.ComponentProps<typeof Label>;
    labelClassName?: string;
  };

type AddonProps = {
  children: React.ReactNode;
  isFilled?: boolean;
  className?: string;
  error?: boolean;
};

const Addon = ({ children, className, error }: AddonProps) => (
  <div
    className={cn(
      "addon-wrapper h-10 border border-input pl-3",
      // isFilled && "bg-gray-100",
      className,
    )}>
    <div className={cn("flex h-full flex-col justify-center text-sm", error && "text-red-900")}>
      <span className="py-.5 whitespace-nowrap">{children}</span>
    </div>
  </div>
);

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  function InputField(props, ref) {
    const id = useId();
    const { t: _t, isLocaleReady, i18n } = useLocale();
    const t = props.t ?? _t;
    const name = props.name ?? "";
    const {
      label = t(name),
      labelProps,
      labelClassName,
      className,
      addOnLeading,
      addOnSuffix,
      addOnFilled = true,
      addOnClassname,
      inputIsFullWidth,
      hint,
      type,
      hintErrors,
      error,
      labelSrOnly,
      containerClassName,
      readOnly,
      // required,
      ...passThrough
    } = props;

    let { variant = "normal" } = props;

    const placeholder: string =
      isLocaleReady && i18n.exists(name + "_placeholder") ? t(name + "_placeholder") : "";

    const [inputValue, setInputValue] = useState<string>("");

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let fieldErrors: FieldErrors<T> | undefined;
    // Check if field has custom errors
    const methods = useFormContext() as ReturnType<typeof useFormContext> | null;
    /* If there's no methods it means we're using these components outside a React Hook Form context */
    if (methods) {
      const { formState } = methods;
      fieldErrors = formState.errors[name];
    }

    const hasErrors = error ?? hintErrors ?? fieldErrors;
    if (hasErrors) variant = "error";
    let icon = null;
    if (variant !== "normal") {
      icon = <InputIcon className="absolute right-[10px] top-[10px]" variant={variant} />;
    }

    return (
      <div
        className={cn(
          "rounded-[4px] [&_.group:focus-within_.addon-wrapper]:border-pink [&_.group:hover_.addon-wrapper]:border-gray-400",
          containerClassName,
        )}>
        {label && (
          <Label
            htmlFor={id}
            {...labelProps}
            className={cn(
              labelClassName,
              labelSrOnly && "sr-only",
              variant === "error" && "text-red-600",
            )}>
            {label}
          </Label>
        )}
        {addOnLeading || addOnSuffix ? (
          <div
            className={cn(
              "group relative flex items-center rounded-[4px] bg-white",
              "focus-within:outline-none focus-within:ring-[3px] focus-within:ring-pink/30",
            )}>
            {addOnLeading && (
              <Addon
                isFilled={addOnFilled}
                className={cn(
                  "rounded-l-[4px] border-r-0",
                  variant === "success" && "border-green",
                  variant === "warning" && "border-orange",
                  variant === "error" && "border-red-600",
                  addOnClassname,
                )}>
                {addOnLeading}
              </Addon>
            )}
            <Input
              id={id}
              type={type}
              placeholder={placeholder}
              isFullWidth={inputIsFullWidth}
              className={cn(
                className,
                addOnLeading &&
                  "addon-wrapper rounded-l-none border-l-0 focus:border-pink focus:border-l-input",
                addOnSuffix && "addon-wrapper rounded-r-none",
                type === "search" && "pr-8",
                variant === "success" && "border-green",
                variant === "warning" && "border-orange",
                variant === "error" && "border-red-600",
                "!my-0 !ring-0",
              )}
              {...passThrough}
              {...(type == "search" && {
                onChange: (e) => {
                  setInputValue(e.target.value);
                  props.onChange && props.onChange(e);
                },
                value: inputValue,
              })}
              readOnly={readOnly}
              ref={ref}
            />
            {addOnSuffix && (
              <Addon
                isFilled={addOnFilled}
                className={cn(
                  "rounded-r-[4px] border-l-0",
                  variant === "success" && "border-green",
                  variant === "warning" && "border-orange",
                  variant === "error" && "border-red-600",
                  addOnClassname,
                )}>
                {addOnSuffix}
              </Addon>
            )}
            {type === "search" && inputValue.toString().length > 0 && (
              <X
                className="top-3.2 absolute right-2 h-4 w-4 cursor-pointer text-gray-500"
                onClick={(e) => {
                  setInputValue("");
                  props.onChange &&
                    props.onChange(e as unknown as React.ChangeEvent<HTMLInputElement>);
                }}
              />
            )}
          </div>
        ) : (
          <div className="relative">
            <Input
              id={id}
              type={type}
              placeholder={placeholder}
              className={cn(
                className,
                variant === "success" && "border border-green hover:border-green-500",
                variant === "warning" && "border border-orange hover:border-orange-500",
                variant === "error" && "border border-red-600 hover:border-red-400",
                icon && "pr-9",
              )}
              {...passThrough}
              readOnly={readOnly}
              ref={ref}
              isFullWidth={inputIsFullWidth}
            />
            {icon}
          </div>
        )}
        <HintsOrErrors hintErrors={hintErrors} fieldName={name} t={t} />
        {hint && <div className="mt-2 flex items-center text-sm text-gray-700">{hint}</div>}
      </div>
    );
  },
);

export const TextField = forwardRef<HTMLInputElement, InputFieldProps>(
  function TextField(props, ref) {
    return <InputField ref={ref} {...props} />;
  },
);

export const PasswordField = forwardRef<HTMLInputElement, InputFieldProps>(
  function PasswordField(props, ref) {
    const { t } = useLocale();
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const toggleIsPasswordVisible = useCallback(
      () => setIsPasswordVisible(!isPasswordVisible),
      [isPasswordVisible, setIsPasswordVisible],
    );
    const textLabel = isPasswordVisible ? t("hide_password") : t("show_password");

    return (
      <div className="relative">
        <InputField
          type={isPasswordVisible ? "text" : "password"}
          placeholder={props.placeholder ?? "•••••••••••••"}
          ref={ref}
          {...props}
          className={cn("mb-0 border-r-0 pr-10", props.className)}
          addOnFilled={false}
          addOnSuffix={
            <Tooltip content={textLabel}>
              <button
                className="absolute bottom-0 right-3 h-10 bg-transparent text-gray-900"
                type="button"
                onClick={() => toggleIsPasswordVisible()}>
                {isPasswordVisible ? (
                  <EyeOff className="h-4 stroke-[2.5px]" />
                ) : (
                  <Eye className="h-4 stroke-[2.5px]" />
                )}
                <span className="sr-only">{textLabel}</span>
              </button>
            </Tooltip>
          }
        />
      </div>
    );
  },
);

export const InputOnClick = forwardRef<HTMLInputElement, InputFieldProps & { isOpen?: boolean }>(
  function InputOnClick(props, ref) {
    const { error, isOpen, ...passThrough } = props;
    const [isFocused, setIsFocused] = useState(isOpen);

    return (
      <>
        {isFocused ? (
          <Input
            ref={ref}
            autoCapitalize="none"
            autoCorrect="off"
            autoFocus
            onBlur={() => setIsFocused(false)}
            {...passThrough}
          />
        ) : (
          <div className="flex flex-row items-center">
            {error && (
              <Tooltip content={error}>
                <AlertTriangle className="mr-2 h-4 w-4 text-orange-400" />
              </Tooltip>
            )}
            <div onClick={() => setIsFocused(true)}>{props.value}</div>
          </div>
        )}
      </>
    );
  },
);

export const EmailInput = forwardRef<HTMLInputElement, InputFieldProps>(
  function EmailInput(props, ref) {
    return (
      <Input
        ref={ref}
        type="email"
        autoCapitalize="none"
        autoComplete="email"
        autoCorrect="off"
        inputMode="email"
        {...props}
      />
    );
  },
);

export const EmailField = forwardRef<HTMLInputElement, InputFieldProps>(
  function EmailField(props, ref) {
    return (
      <InputField
        ref={ref}
        type="email"
        autoCapitalize="none"
        autoComplete="email"
        autoCorrect="off"
        inputMode="email"
        {...props}
      />
    );
  },
);

type TextAreaProps = JSX.IntrinsicElements["textarea"] & { isErrorPresent?: boolean };

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(function TextAreaInput(
  { isErrorPresent, className, ...props },
  ref,
) {
  return (
    <textarea
      ref={ref}
      {...props}
      className={cn(
        "block w-full rounded-md px-3 py-2 text-sm",
        "border border-input hover:border-gray-400",
        "focus:border-pink focus:outline-none focus:ring-[3px] focus:ring-pink/30",
        className,
        isErrorPresent && "border-red-600 hover:border-red-400 focus:border-red-600",
      )}
    />
  );
});

type TextAreaFieldProps = {
  label?: ReactNode;
  t?: (key: string) => string;
} & React.ComponentProps<typeof TextArea> & {
    name: string;
    labelProps?: React.ComponentProps<typeof Label>;
  };

export const TextAreaField = forwardRef<HTMLTextAreaElement, TextAreaFieldProps>(
  function TextField(props, ref) {
    const id = useId();
    const { t: _t } = useLocale();
    const t = props.t ?? _t;
    const methods = useFormContext();
    const {
      label = t(props.name),
      labelProps,
      /** Prevents displaying untranslated placeholder keys */
      ...passThrough
    } = props;

    const placeholder: string =
      t(props.name + "_placeholder") !== props.name + "_placeholder"
        ? t(props.name + "_placeholder")
        : "";
    const errorMessage = methods.formState.errors[props.name]?.message;

    return (
      <div>
        {!!props.name && (
          <Label htmlFor={id} {...labelProps} className={cn(!!errorMessage && "text-red-600")}>
            {label}
          </Label>
        )}
        <TextArea
          ref={ref}
          placeholder={placeholder}
          {...passThrough}
          isErrorPresent={!!errorMessage}
        />
        {errorMessage && <HintsOrErrors hintErrors={undefined} fieldName="description" t={t} />}
      </div>
    );
  },
);

type FormProps<T extends object> = {
  form: UseFormReturn<T>;
  handleSubmit: SubmitHandler<T>;
} & Omit<JSX.IntrinsicElements["form"], "onSubmit">;

const PlainForm = <T extends FieldValues>(props: FormProps<T>, ref: Ref<HTMLFormElement>) => {
  const { form, handleSubmit, ...passThrough } = props;

  return (
    <FormProvider {...form}>
      <form
        ref={ref}
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();
          form
            .handleSubmit(handleSubmit)(event)
            .catch((err) => {
              // FIXME: Booking Pages don't have toast, so this error is never shown
              showToast(`${getErrorFromUnknown(err).message}`, "error");
            });
        }}
        {...passThrough}>
        {props.children}
      </form>
    </FormProvider>
  );
};

export const Form = forwardRef(PlainForm) as <T extends FieldValues>(
  p: FormProps<T> & { ref?: Ref<HTMLFormElement> },
) => ReactElement;

export function FieldsetLegend(props: JSX.IntrinsicElements["legend"]) {
  return (
    <legend {...props} className={cn("text-sm font-medium text-gray-700", props.className)}>
      {props.children}
    </legend>
  );
}

export function InputGroupBox(props: JSX.IntrinsicElements["div"]) {
  return (
    <div
      {...props}
      className={cn("space-y-2 rounded-sm border border-input bg-white p-2", props.className)}>
      {props.children}
    </div>
  );
}
