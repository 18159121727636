import type { JSX } from "react";

import { cn } from "@gility/lib";

export function Label(props: JSX.IntrinsicElements["label"]) {
  return (
    <label
      {...props}
      className={cn("mb-2 block font-medium leading-none text-blue", props.className)}>
      {props.children}
    </label>
  );
}
