import { cn } from "@gility/lib";

import { AlertCircle, Check } from "../../icon";

export function InputIcon({
  variant,
  className,
}: {
  variant?: "normal" | "success" | "warning" | "error";
  className?: string;
}) {
  let icon = <></>;
  switch (variant ?? "normal") {
    case "normal":
      break;
    case "success":
      icon = (
        <Check size="16" strokeWidth="4" className={cn("inline-block text-green", className)} />
      );
      break;
    case "warning":
      icon = (
        <AlertCircle
          size="16"
          strokeWidth="3"
          className={cn("inline-block text-orange", className)}
        />
      );
      break;
    case "error":
      icon = (
        <AlertCircle
          size="16"
          strokeWidth="3"
          className={cn("inline-block text-pink", className)}
        />
      );
      break;
  }

  return icon;
}
